<template>
    
  <div class="row justify-center">
    <h4>La charte Mandragore</h4>
  </div>

  <div class="row justify-center">
    <div class="col-xs-10 col-md-6">

      <h5>
        A - Mandragore
      </h5>

      <h6 class="regular-text indent">
        A.1 - Mandragore, qu’est-ce ?
      </h6>

      <div class="regular-text text-justify">
        Le groupe Mandragore est historiquement un ensemble de joueurs de WoW, au-delà des appartenances de guilde et des considérations liées au jeu d'équipe. Ce groupe s’est défini autour de valeurs sociales et humaines qui sont parfois oubliées, éclipsées dans les jeux en ligne, et dont il s’est fait le porteur. L’idée de départ pour la fondation de Mandragore a été un constat dans WoW proche de la "formule des forums", très connue des anglophones : "Personne normale + Anonymat + Audience = Crétin néfaste". Cette équation pouvait être facilement démantelée avec un peu d'efforts et de cœur et c'est ce qui s’est passé dans ce groupe.
        <br/>
        <br/>
        Aujourd’hui, Mandragore est une association loi de 1901 et dont la portée franchit désormais les barrières du jeu vidéo. Étant naturellement focalisée sur les qualités intrinsèques des joueurs et non de leurs personnages, Mandragore est devenu un groupe d’amis dont les frontières sont les personnes elles-mêmes et non plus des supports.
      </div>

      <h6 class="regular-text indent">
        A.2 - Qui fait partie de l’association Mandragore ?
      </h6>

      <div class="regular-text text-justify">
        Ses membres se choisissent par cooptation, sur recommandation de l'un d'entre eux et acceptation des autres. Pour entrer chez Mandragore, il faut y avoir été invité, et avoir été présenté dans un post de parrainage rédigé par son parrain. Il faut en outre s’être acquitté de la cotisation annuelle.
      </div>

      <h6 class="regular-text indent">
        A.3 - Qui fait partie du groupe Mandragore ?
      </h6>

      <div class="regular-text text-justify">
        Vous pouvez faire partie du groupe Mandragore si vous n’êtes pas cotisants. Vous n’aurez simplement pas droit de vote lors de l’assemblée générale. Il faudra cependant avoir été parrainé de la même manière que les membres de l’association.
      </div>

      <h6 class="regular-text indent">
        A.4. Quel est l’intérêt ?
      </h6>

      <div class="regular-text text-justify">
        Les fondateurs de Mandragore, au travers de leurs expériences personnelles et professionnelles, ont fait le constat précoce de combien les communautés en ligne peuvent se révéler délicates en termes de vivre ensemble : incompréhensions, mauvaise communication, voire même émergence de situations parfaitement toxiques (on peut considérer le cas d’école de la communauté des joueurs de League of Legends).<br>
        Leur défi a donc été de porter, faire vivre et propager la volonté d'écouter, de comprendre, de désamorcer les tensions qui ne naissent souvent qu'au détour d'une ligne de texte qui n'est pas lue avec le ton ou le sens avec lequel elle fut écrite.
      <br/>
      <br/>
        Aujourd’hui, l’association Mandragore a été mise sur pied afin de donner une substance et une persistance à ces liens qui se sont tissés entre nous. Depuis 2009, nous avons tous grandis, franchis cette période “étude & wow”, et certains écument désormais d’autres océans et ne participent plus autant à l’activité quotidienne du groupe. Ils restent néanmoins des camarades, des copains, des amis, et veulent garder contact avec ce cercle.<br>
        D’un point de vue pragmatique, l’association s’avère également être un outil pratique pour organiser nos retrouvailles et gérer nos activités IRL.
      </div>

      <h5>
        B - Nous, Mandragores
      </h5>

      <div class="regular-text text-justify">
        La guilde Mandragore a été le point de départ de ce mouvement, en 2009. Elle est encore aujourd’hui sur le serveur Kael’thas de World of Warcraft, et offre à qui le souhaite une structure pour se retrouver en jeu, pour échanger, pour s'entraider.
      </div>

      <h6 class="regular-text indent">
        B.1 - Qui en fait partie ?
      </h6>

      <div class="regular-text text-justify">
        Les membres, et bien, ce sont les Mandragores !
        N'importe qui peut intégrer la structure afin de nous découvrir. Une fois intégré, le nouveau venu dispose d’un temps compté durant lequel il découvre le groupe mais est aussi examiné sous toutes les coutures, une occasion pour lui de faire découvrir aux autres membres sa sympathie et ses qualités qui l’ont amenées ici, en attendant un éventuel parrainage.
        Un ami ou un membre de famille peut aussi être directement intégré chez Mandragore. Cette exception repose sur la confiance que nous avons dans le parrain, sous réserve que la jeune recrue respecte les règles de savoir-être et de savoir-vivre telles que décrites dans cette charte.
        Ensemble, les membres sont les garants de l'esprit Mandragore, et se font forts de le défendre, le rappeler, le raviver, l'incarner et le matérialiser de par leur discours et leur exemple.
        Ils peuvent avoir des  grades, qui représentent les diverses fonctions qu’ils exercent au sein de l’association. Ces grades peuvent évoluer dans le temps et matérialisent une forme d’organigramme fonctionnel pour se mettre au service du groupe ensembles et le mieux possible.
      </div>


      <h6 class="regular-text indent">
        B.2 - Qu’attend-on de nos confrères ?
      </h6>

      <div class="regular-text text-justify">
        Un peu d'effort et de cœur, ce qui concrètement se traduit par :<br>
        <ol>
          <li>La politesse. C'est bête mais ça marche diaboliquement bien. Il est facile d'utiliser la grossièreté ou la vulgarité comme forme d'humour, et ça peut tout à fait être drôle. Mais ne le faites qu'avec un auditoire dont vous connaissez totalement le sens de l'humour. Si un propos ou une phrase vous choque ou vous déplait, faites le remarquer à son auteur gentiment, laissez-lui le bénéfice du doute. Si vous êtes l'objet d'une telle remarque, n'hésitez pas à vous excuser sans orgueil mal placé ni pudeur. D'un côté comme de l'autre, évitez les procès d'intention, ne considérez pas l'autre comme une nonne coincée ou un repris de justice attardé.</li>
          <li>La gentillesse. L'entraide, le conseil, l'écoute, la générosité ... choisissez la vôtre. C'est le nerf de la guerre. C'est ça qui au final fera de ce groupe un endroit où il fait bon vivre.</li>
          <li>Prendre sur soi. Tout le monde a des soucis, des choses qui dérangent, dans le jeu ou en dehors. Nous demandons à nos membres de contribuer de façon positive à l’ambiance du groupe. Les râleries, les « moi je », les comportements qui consistent à demander ce que les autres peuvent faire pour vous au lieu de ce que vous pouvez faire pour les autres, ne sont pas les bienvenus, et seront systématiquement traqués par l’encadrement et passibles de renvoi.</li>
          <li>L'expression. Soignez votre écriture : c'est une forme de respect, c'est le fil avec lequel se tissent les conversations et -au travers de ces dernières- les relations sur un support virtuel où l'on écrit beaucoup. Il ne s'agit pas d'être irréprochable, juste de faire attention.</li>
          <li>L'image. Donnez envie aux autres, et véhiculez une image positive de notre groupe. Qu'on nous considère comme des nullos, pas de soucis, mais des nullos avec la classe, l'élégance, le style, la distinction, une grande sympathie et une grande empathie.</li>
        </ol>
      </div>

      <h6 class="regular-text indent">
        B.3 - Quels objectifs ?
      </h6>

      <div class="regular-text text-justify">
        De prime abord, s’assurer que tous les Mandragores profitent d’un espace commun à la fois agréable, et pratique pour s’adonner aux activités qui les ont réunies, comme jouer à WoW par exemple.
        <br>
        <br>
        Mais sur le long terme, aboutir à la construction d’un cercle d’amis partageant des valeurs humaines, et pouvant interagir, partager et s’entraider, sur autant de sujets possibles.
      </div>

    </div>
  </div>

  <div class="row justify-center" style="margin-top: 40px; margin-bottom: 30px;">
    <div class="col-xs-10 col-md-6" style="display: flex; justify-content: space-evenly;">

    <router-link to="/association/fonctionnement">
      <q-btn color="primary" label="◄&nbsp;&nbsp;&nbsp;Le fonctionnement" />
    </router-link>

    <router-link to="/association/asso">
      <q-btn color="primary" label="L'asso&nbsp;&nbsp;&nbsp;►" />
    </router-link>

  </div>
</div>  

</template>  


<style scoped>

.list-element {
  margin-bottom: 10px;
}

.indent {
  text-indent: 50px;
}

</style>